import { Link } from 'react-router-dom';

const Post = (props) => {
  return (
    <>
      <Link to={`/post/${props.id}`}>
        <article className="c9nf7p260c">
          <div className="post-info">
            <div>
              <p className="n7bj7bw282">{props.author}</p>
              <p>{props.date}</p>
            </div>
            <h2>{props.title}</h2>
          </div>
          <img src={props.cover} alt="cover" />
        </article>
      </Link>
    </>
  );
};

export default Post;
