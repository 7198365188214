import OnScreen from '../hooks/OnScreen';

const ScienceNum = () => {
  return (
    <>
      <OnScreen>
        <section className="section">
          <div className="wrapper small">
            <div className="section-title">
              <h2 className="title-alt">
                ScienceNum
                <br />
                Un projet collaboratif
              </h2>
            </div>
            <p className="text">
              ScienceNum est un projet collaboratif visant à faciliter l’accès
              des enseignants au pacte d’excellence, en alliant sciences,
              mathématiques et numérique au service de la créativité.
            </p>
            <p className="text">
              A l’initiative de l’asbl Hypothèse, Kodo Wallonie, Scienceinfuse
              et SparkOH! se sont associés pour développer et proposer des
              séquences d’apprentissage pour les élèves de la 3e primaire
              jusqu’à la 2e secondaire.
            </p>
            <img
              className="img-full rad"
              src="img/num-01.jpg"
              alt="STEAMagine"
            />
            <p className="text">
              Ces séquences sont accessibles en ligne et s’accompagnent de
              malles pédagogiques à prêter. Elles font également l’objet de
              journées de formation prestées dans le cadre de la formation
              continuée des enseignants via l’IFPC.
            </p>
            <p className="text text-center">
              Avec le soutien du Service public de Wallonie Économie, Emploi et
              Recherche.
            </p>
            <br />
            <div className="container col-2">
              <a
                href="https://hypothese.be/projet/science-num/"
                target="_blank"
                rel="noreferrer"
                className="rnig04d673 g200mv1sa1 support violet"
              >
                <p className="centext">Vers les ressources</p>
              </a>
              <a
                href="https://ifpc-fwb.be"
                target="_blank"
                rel="noreferrer"
                className="rnig04d673 g200mv1sa1 support violet"
              >
                <p className="centext">Vers les formations</p>
              </a>
            </div>
            <br />
            <p className="title-cat text-balance">
              Ce projet vise la création d’activités de sensibilisation au
              fonctionnement des objets numériques en développant des
              dispositifs ayant pour objectifs :
            </p>
            <p className="text-center text-mb">
              1. D’accompagner les enseignants dans la démystification du
              fonctionnement des objets et outils numériques en lien avec les
              nouveaux référentiels du Pacte d’Excellence;
            </p>
            <p className="text-center text-mb">
              2. D’illustrer les rôles du numérique dans la société, ses enjeux
              et implications;
            </p>
            <p className="text-center text-mb">
              3. De présenter les métiers STEM et travailler sur leurs
              représentations auprès des élèves, tout en cassant les stéréotypes
              liés à ces métiers / fillières.
            </p>
            <br />
            <br />
            <br />
            <br />
            <h2 className="title-alt">
              De la graine à la plante, une histoire à raconter
            </h2>
            <p className="text">
              Pour les P3 (convient également au P4). Disponible dès le 30 juin
              2024.
            </p>
            <img
              className="img-full m20 rad"
              src="img/num-02.jpg"
              alt="STEAMagine"
            />
            <p className="text">
              Les élèves vivront une démarche de recherche pour découvrir la
              notion botanique de « fruit », partie de plante contenant les
              graines, et déterminer les besoins de celle-ci pour germer
              (Sciences). En parallèle, ils apprendront à « créer un contenu
              numérique » (FMTTN), plus précisément à prendre une image
              numérique, la traiter, la nommer, l’annoter afin de créer leur «
              cahier de traces numériques ».
            </p>
            <br />
            <br />
            <br />
            <br />
            <h2 className="title-alt">
              Plante connectée, la reproduction des plantes à fleurs
            </h2>
            <p className="text">
              Pour les P5 &gt; Disponible dès le 20 décembre 2024.
            </p>
            <img
              className="img-full m20 rad"
              src="img/num-03.png"
              alt="STEAMagine"
            />
            <p className="text">
              Les élèves exploreront le cycle de vie des plantes à fleurs, avec
              un focus sur la pollinisation et l’apparition du fruit (Sciences).
              En parallèle, ils pourront maintenir en classe une plante verte
              grâce à un petit système d’arrosage automatique qu’ils
              assembleront et programmeront eux-mêmes, s’initiant ainsi à la
              logique de programmation (FMTTN).
            </p>
            <br />
            <br />
            <br />
            <br />
            <h2 className="title-alt">L’isolation de la maison</h2>
            <p className="text">
              Pour les P5- P6 &gt; Disponible dès le 30 juin 2024.
            </p>
            <img
              className="img-full m20 rad"
              src="img/num-04.jpg"
              alt="STEAMagine"
            />
            <p className="text">
              Les élèves découvriront les transferts de chaleur et les isolants
              thermiques sur base de manipulations concrètes autour de maquette
              de maison (Sciences). Les hypothèses quant aux meilleurs isolants
              seront vérifiées par l’analyse de relevés de températures obtenus
              automatiquement par un petit système de thermostat installé et
              programmé par les élèves (FMTTN).
            </p>
            <br />
            <br />
            <br />
            <br />
            <h2 className="title-alt">Machines automatisées à engrenages</h2>
            <p className="text">
              Pour les P5-P6 &gt; Disponible dès le 30 juin 2024.
            </p>
            <img
              className="img-full m20 rad"
              src="img/num-05.jpg"
              alt="STEAMagine"
            />
            <p className="text">
              Les élèves exploreront les concepts de machines simples par
              l’expérimentation (engrenages, poulies…). Ils vérifieront leurs
              hypothèses grâce à l’usage d’un système automatisé qu’ils auront
              eux-mêmes programmé.
            </p>
            <p className="text">
              Pour tout renseignement concernant l'emprunt des malles, merci de
              contacter le partenaire lié à votre zone géographique :
            </p>
            <div className="container col-2">
              <a
                href="https://sciencesaemporter.be/"
                target="_blank"
                rel="noreferrer"
                className="rnig04d673 g200mv1sa1 support"
              >
                <p className="centext">
                  Brabant Wallon <br />
                  <span className="spantext">Scienceinfuse</span>
                </p>
              </a>
              <a
                href="mailto:hello@sparkoh.be"
                rel="noreferrer"
                className="rnig04d673 g200mv1sa1 support"
              >
                <p className="centext">
                  Hainaut <br />
                  <span className="spantext">SparkOH!</span>
                </p>
              </a>
            </div>
            <div className="container col-2">
              <a
                href="mailto:contact@hypothese.be"
                rel="noreferrer"
                className="rnig04d673 g200mv1sa1 support"
              >
                <p className="centext">
                  Liège <br />
                  <span className="spantext">Hypothèse</span>
                </p>
              </a>
              <a
                href="mailto:contact@kodowallonie.be"
                rel="noreferrer"
                className="rnig04d673 g200mv1sa1 support"
              >
                <p className="centext">
                  Namur / Luxembourg <br />
                  <span className="spantext">Kodo Wallonie</span>
                </p>
              </a>
            </div>
            <p className="title-cat">Partenaires :</p>
            <div className="container col-2">
              <a
                href="https://hypothese.be/projet/science-num/"
                target="_blank"
                rel="noreferrer"
                className="rnig04d673 g200mv1sa1 support"
              >
                <img src="img/sponsor-01.png" alt="ScienceNum" />
              </a>
              <a
                href="https://hypothese.be/"
                target="_blank"
                rel="noreferrer"
                className="rnig04d673 g200mv1sa1 support"
              >
                <img src="img/sponsor-02.png" alt="Hypothèse" />
              </a>
            </div>
            <div className="container col-2">
              <a
                href="https://sparkoh.be/science-num-un-projet-collaboratif/"
                target="_blank"
                rel="noreferrer"
                className="rnig04d673 g200mv1sa1 support"
              >
                <img src="img/sponsor-04.png" alt="Sparkoh!" />
              </a>
              <a
                href="https://uclouvain.be/fr/decouvrir/scinfuse"
                target="_blank"
                rel="noreferrer"
                className="rnig04d673 g200mv1sa1 support"
              >
                <img src="img/sponsor-03.png" alt="Science Infuse" />
              </a>
            </div>
            <div className="wrapper-soutien">
              <img className="soutien" src="img/soutien.png" alt="soutien" />
            </div>
          </div>
        </section>
      </OnScreen>
    </>
  );
};

export default ScienceNum;
