const SourceContent = ({ menuItem }) => {
  return (
    <div className="item container col-2">
      {menuItem.map((item) => {
        return (
          <div className="n88w3e0kx1 auto" key={item.id}>
            <div className="item-container">
              <a
                className="item-con"
                href={item.link}
                target="_blank"
                rel="noreferrer"
              >
                <div className={item.color}></div>
                <h2>{item.title}</h2>
                <p>{item.description}</p>
              </a>
            </div>
          </div>
        );
      })}
    </div>
  );
};

export default SourceContent;
