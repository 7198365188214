import dataBlog from '../data/dataBlog';
import Post from '../comp/Post';
import EventNew from '../comp/EventNew';
import OnScreen from '../hooks/OnScreen';
import { Link } from 'react-router-dom';
import '../styles/_home.css';

const Home = () => {
  return (
    <>
      <section id="hero">
        <div className="wrapper">
          <div className="container col-2">
            <div className="w7g2r29tb5">
              <h1 className="hero-subtitle">Kodo Wallonie</h1>
              <img className="line" src="svg/line.svg" alt="line" />
              <p className="hero-title">
                L'association des passionné.es de technologies
              </p>
              <a
                href="pdf/brochure.pdf"
                target="_blank"
                rel="noreferrer"
                className="link-btn"
              >
                Notre offre d'activités
              </a>
              <div className="terminal">
                <img className="dots" src="svg/dots.svg" alt="dots" />
                <p>$ mkdir collab && cd collab</p>
                <p>
                  $ npm i kodo-wallonie{' '}
                  <span className="light-blue">--save</span>
                </p>
                <p className="space">
                  Run <span className="light-blue">create-project</span> to see
                  all activities 🚀✨
                </p>
                <p>$ _</p>
              </div>
            </div>
            <div className="hero-img">
              <img className="mascot" src="img/core/mascot.png" alt="Marvin" />
              <img className="hello" src="svg/hello.svg" alt="hello" />
            </div>
          </div>
        </div>
      </section>
      <section id="about">
        <div className="wrapper">
          <div className="container col-3">
            <h2 className="about-title">
              Réduisons <br />
              la fracture
              <br />
              numérique
            </h2>
            <div className="about-wrapper">
              <img className="fail" src="svg/fail.svg" alt="fail" />
              <p>
                Nous avons une réelle volonté de rendre les usagers acteurs de
                ce monde. Depuis 2015 lʼéquipe multidisciplinaire fait découvrir
                les coulisses des outils quotidiens au plus grand nombre, petits
                et grands, grâce à des ateliers, des rencontres ainsi que
                d’autres événements autour du numérique et des sciences
                informatiques.
              </p>
            </div>
          </div>
        </div>
      </section>
      <OnScreen>
        <section id="skills">
          <div className="wrapper">
            <div className="title">Notre expertise</div>
            <div className="container col-2">
              <Link to="/schools" className="pa14fk32w7">
                <p>
                  Avec les <br />
                  écoles
                </p>
                <img src="svg/schools.svg" alt="schools" />
              </Link>
              <Link to="/cultural" className="pa14fk32w7">
                <p>
                  Avec le <br />
                  culturel
                </p>
                <img src="svg/cultural.svg" alt="cultural" />
              </Link>
              <Link to="/families" className="pa14fk32w7">
                <p>
                  Avec les <br />
                  familles
                </p>
                <img src="svg/families.svg" alt="families" />
              </Link>
              <Link to="/companies" className="pa14fk32w7">
                <p>
                  Avec les <br />
                  entreprises
                </p>
                <img src="svg/companies.svg" alt="companies" />
              </Link>
            </div>
          </div>
        </section>
        <section id="partners">
          <div className="wrapper">
            <div className="title">Nos partenaires</div>
            <div className="kea788ot36">
              <p className="partners-text">
                9 ans d'existence et 8 ans de travail autour du numérique pour
                les écoles et organismes socio-culturels en Wallonie. Ce travail
                n'aurait pu se faire sans le soutien de nos partenaires
                institutionnels.
              </p>
            </div>
            <div className="container col-2">
              <a
                href="https://www.wallonie.be/"
                target="_blank"
                rel="noreferrer"
                className="rnig04d673 g200mv1sa1"
              >
                <img
                  className="img-wallonie"
                  src="svg/wallonie.svg"
                  alt="fail"
                />
                <p>Wallonie.be</p>
              </a>
              <a
                href="https://www.digitalwallonia.be/"
                target="_blank"
                rel="noreferrer"
                className="rnig04d673 g200mv1sa1"
              >
                <img
                  className="img-wallonia"
                  src="svg/wallonia.svg"
                  alt="fail"
                />
                <p>
                  Digital <br />
                  wallonia.be
                </p>
              </a>
            </div>
          </div>
        </section>
      </OnScreen>
      <section id="actuality">
        <div className="wrapper">
          <div className="title">Notre articles</div>
          <div className="container col-3">
            {dataBlog
              .map((post, index) => (
                <Post
                  key={'Post' + index}
                  id={post.id}
                  date={post.date}
                  title={post.title}
                  author={post.author}
                  cover={post.cover}
                />
              ))
              .reverse()}
          </div>
          <div className="ma">
            <Link to="/blog" className="link-btn">
              Plus d’articles
            </Link>
          </div>
        </div>
      </section>
      <section id="events">
        <div className="wrapper">
          <div>
            <div className="title">Nos événements</div>
            <div className="container col-2">
              <EventNew />
            </div>
          </div>
        </div>
        <div className="ma">
          <Link to="/agenda" className="link-btn">
            Toutes nos dates
          </Link>
        </div>
      </section>
      <section id="games">
        <div className="wrapper">
          <div className="container">
            <a
              className="uu2989o8pv"
              href="https://kodowallonie.itch.io/"
              target="_blank"
              rel="noreferrer"
            >
              <p className="b1f53f84ez">Nos jeux itch.io 🔥</p>
              <p>
                Les jeunes rencontrés et l'équipe vous partagent leurs créations
                vidéoludiques. Jouez et n'hésitez pas à nous laisser vos retours
                sur notre page Facebook.
              </p>
              <img src="svg/arcade.svg" alt="arcade" />
            </a>
          </div>
        </div>
      </section>
    </>
  );
};

export default Home;
